<template>
  <b-container v-if="getFarmById(farmId)">
    <b-row class="mb-1">
      <b-col cols="12">
        <h2>Ferme {{ getFarmById(farmId).name }}</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5">
        <b-form @submit.stop.prevent="sendFarmerInvite">
          <b-form-group
            id="input-group-1"
            label="Adresse courriel"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="email@fai.com"
              required
            />
          </b-form-group>

          <div class="text-right">
            <b-button
              type="submit"
              variant="primary"
            >
              <feather-icon icon="SendIcon" />
              Envoyer
            </b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col cols="12">
        <h3>Invitations en attente</h3>
        <div
          v-if="farmerContacts.pending.length"
          style="background-color: #fff;"
        >
          <b-table
            striped
            :items="farmerContacts.pending"
            :fields="pendingFields"
          >
            <template #cell(invite_sent_at)="row">
              <div class="invite-cell">
                <p>
                  {{ dayjs().to(row.item.invite_sent_at) }}
                </p>
                <b-button
                  v-if="listSlug !== 'industries'"
                  type="button"
                  variant="primary"
                  @click="sendFarmerInvite(row.item.email)"
                >
                  Renvoyer
                </b-button>

              </div>
            </template>
          </b-table>
        </div>
        <p
          v-else
          class="text-muted font-italic"
        >
          Aucune invitation en attente
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h3>Liste des exploitants enregistrés</h3>
      </b-col>
      <b-col
        cols="12"
        class="p-0 ml-1"
      >
        <div
          v-if="farmerContacts.active.length > 0"
          tyle="background-color: #fff;"
        >
          <b-table
            striped
            :items="farmerContacts.active"
          />
        </div>
        <p
          v-else
          class="text-muted font-italic"
        >
          Aucun exploitant inscrit.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BTable
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import farmListMixins from "@/mixins/farmList";
import dayjs from "dayjs";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable
  },
  mixins: [farmListMixins],
  data() {
    return {
      form: {
        email: ""
      },
      farmerContacts: {
        pending: [],
        active: []
      },
      farmId: this.$route.params.id,
      pendingFields: [
        "email",
        {
          key: "invite_sent_at",
          label: "Dernière invitation envoyée"
        }
      ]
    };
  },
  computed: {
    ...mapGetters("cooperative", ["getFarmById"])
  },
  created() {
    this.getAllCooperativeFarms();
    this.getFarmerList();
  },
  methods: {
    getFarmerList() {
      if (this.farmId) {
        this.$http.ListFarmUsers({ id: this.farmId })
          .then(({ data }) => {
            this.farmerContacts.active = data.active;

            this.farmerContacts.pending = data.pending;
          })
          .catch(console.warn);
      }
    },
    sendFarmerInvite(email = "") {
      if (this.farmId) {
        this.$http.InviteFarmer(
          { id: this.farmId },
          {
            invitee_attributes: {
              email: email.length > 0 ? email : this.form.email
            }
          }
        )
          .then(({ data }) => {
            this.farmerContacts.pending = data.pending;
          })
          .catch(console.warn);

        this.form.email = "";
      }
    },
    dayjs
  }
};
</script>

<style lang="scss" scoped>
.invite-cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    width: 220px;
    margin: 0;
  }
}
</style>
